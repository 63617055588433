import {handleKeyPressEnterOrSpace, SHARE_BUTTON_NAMES} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {SocialNetworks, shareEvent as shareOn} from '../../../commons/services/share'
import {Facebook as FacebookIcon, Linkedin as LinkedinIcon, Twitter as TwitterIcon} from '../../../icons/dist'
import * as cl from '../classnames.scss'
import * as s from './social-share.scss'
import {SocialShareProps} from '.'

const {Facebook, LinkedIn, Twitter} = SocialNetworks

const getIconsMeasurements = (mobile: boolean) => ({
  [Facebook]: mobile ? {width: '8', height: '16'} : {width: '10', height: '20'},
  [Twitter]: mobile ? {width: '18', height: '14'} : {width: '23', height: '17'},
  [LinkedIn]: mobile ? {width: '18', height: '16'} : {width: '22', height: '20'},
})

export class SocialShare extends React.Component<SocialShareProps> {
  share = (socialNetwork: SocialNetworks) => {
    const {eventUrl, t} = this.props
    shareOn(eventUrl, socialNetwork, t)
  }

  shareOnFacebook = () => {
    this.props.shareEvent(SHARE_BUTTON_NAMES.FACEBOOK, this.props.page)
    this.share(Facebook)
  }

  shareOnTwitter = () => {
    this.props.shareEvent(SHARE_BUTTON_NAMES.TWITTER, this.props.page)
    this.share(Twitter)
  }

  shareOnLinkedin = () => {
    this.props.shareEvent(SHARE_BUTTON_NAMES.LINKEDIN, this.props.page)
    this.share(LinkedIn)
  }

  render() {
    const {t} = this.props

    return (
      <div className={classNames(s.shareIcons, cl.socialShare)} data-hook="social-share">
        <a
          className={s.shareIcon}
          data-hook="social-share-icon-facebook"
          tabIndex={0}
          aria-label={t('accessibility:a11y.shareButtonLabel', {
            button: 'Facebook',
          })}
          onClick={this.shareOnFacebook}
          onKeyPress={handleKeyPressEnterOrSpace(this.shareOnFacebook)}
        >
          <FacebookIcon {...getIconsMeasurements[Facebook]} />
        </a>
        <a
          className={s.shareIcon}
          style={{margin: `0 ${this.props.margin || 30}px`}}
          data-hook="social-share-icon-twitter"
          tabIndex={0}
          aria-label={t('accessibility:a11y.shareButtonLabel', {
            button: 'Twitter',
          })}
          onClick={this.shareOnTwitter}
          onKeyPress={handleKeyPressEnterOrSpace(this.shareOnTwitter)}
        >
          <TwitterIcon {...getIconsMeasurements[Twitter]} />
        </a>
        <a
          className={s.shareIcon}
          data-hook="social-share-icon-linkedin"
          tabIndex={0}
          aria-label={t('accessibility:a11y.shareButtonLabel', {
            button: 'LinkedIn',
          })}
          onClick={this.shareOnLinkedin}
          onKeyPress={handleKeyPressEnterOrSpace(this.shareOnLinkedin)}
        >
          <LinkedinIcon {...getIconsMeasurements[LinkedIn]} />
        </a>
      </div>
    )
  }
}
