const PREFIX = 'events'

export const DH = {
  eventDetails: 'event-details',
  eventDetailsContent: 'content-container',
  eventDetailsFullLocation: 'event-full-location',
  subtitleTimeAndLocation: 'time-and-location',

  header: 'header',
  headerEventTitle: 'event-title',
  headerEventDescription: 'event-description',
  headerShortDateLocation: 'short-date-location',
  headerShortDate: 'event-short-date',
  headerShortLocation: 'event-short-location',
  HEADER_GET_TICKETS_BUTTON: 'get-tickets-button',

  ABOUT_SECTION: 'about-section',
  ABOUT_SECTION_TEXT: 'about-section-text',
  ABOUT_SECTION_BUTTON: 'about-section-button',
  MAP: 'map',

  eventImage: 'event-image',

  CHECKOUT_STEP: (key: string) => `${PREFIX}.CHECKOUT_STEP-${key}`,
  CHECKOUT_STEP_TITLE: `${PREFIX}.CHECKOUT_STEP_TITLE`,
  CHECKOUT_STEP_EDIT: `${PREFIX}.CHECKOUT_STEP_EDIT`,
  CHECKOUT_BUYER_DETAILS: `${PREFIX}.CHECKOUT_BUYER_DETAILS`,
  CHECKOUT_SUMMARY_SUBTOTAL: `${PREFIX}.CHECKOUT_SUMMARY_SUBTOTAL`,
  CHECKOUT_SUMMARY_DISCOUNT: `${PREFIX}.CHECKOUT_SUMMARY_DISCOUNT`,
  CHECKOUT_SUMMARY_TOTAL: `${PREFIX}.CHECKOUT_SUMMARY_TOTAL`,
  SHOW_SUMMARY_BUTTON: 'SHOW_SUMMARY_BUTTON',

  EVENT_DETAILS_MEMBERS: `${PREFIX}.MEMBERS`,
  MEMBERS_TITLE: `members-title`,
  UPDATE_MEMBER_EVENT: `${PREFIX}.UPDATE_MEMBER_EVENT`,
  RSVP_INFO_BUTTON: 'RSVP_INFO_BUTTON',
  TICKET: 'ticket',

  COUPON_CODE_EXPAND_BUTTON: 'coupon-code-expand-button',
  COUPON_FORM: 'coupon-form',
  COUPON_CODE_INPUT: 'coupon-code-input',
  COUPON_CODE_CLEAR: 'coupon-code-clear',
  COUPON_CODE_SUBMIT: 'coupon-code-submit',
  COUPON_ERROR: 'coupon-error',
  COUPON_INPUT_INFO: 'coupon-input-info',

  TICKET_DETAILS_FORM: index => `ticket-details-form-${index}`,

  DESKTOP: 'desktop',
  MOBILE: 'mobile',

  ORDER_SUCCESS: 'order-success',
  ORDER_SUCCESS_TITLE: 'order-success-title',
  ORDER_SUCCESS_SUBTITLE: 'order-success-subtitle',

  INVOICE_TITLES: 'invoice-titles',

  DISCOUNT_PRICE: 'discount-price',
  PAID_PLAN_DISCOUNT_PRICE: 'paid-plan-discount-price',

  TAX: 'tax',
  TAX_LABEL: 'tax-label',

  WIX_FEE: 'wix-fee',

  ORDER_SUCCESS_SUMMARY: 'order-success-summary',
  ORDER_SUCCESS_SUMMARY_WRAPPER: 'order-success-summary-wrapper',

  SUBTOTAL: 'subtotal',

  TOTAL_PRICE: 'total-price',

  BACK_TO_SITE: 'back-to-site',
  PRINT: 'print',
  DOWNLOAD: 'download',

  ORDER_NUMBER: 'order-number',
  PLACED_ON: 'placed-on',

  QUANTITY_PICKER: 'quantity-picker',
  MINUS_BUTTON: 'minus-button',
  PLUS_BUTTON: 'plus-button',
  QUANTITY_VALUE: 'quantity-value',

  RSVP_BUTTON: 'rsvp-button',
  CHECKOUT: 'checkout',
  CHECKOUT_BUTTON: 'checkout-button',
  CLOSE_BUTTON: 'close-button',
  RESERVE_TICKETS_BUTTON: 'reserve-tickets-button',

  FORM_BUTTON: 'form-button',
  FORM_INVALID_FIELD: 'form-invalid-field',
  FORM_ADDRESS_LABEL: 'address-caption',
  FORM_ADDRESS_INPUT: 'address-input',
  FORM_ADDRESS_ERROR: 'address-error',
  FORM_DATE_LABEL: 'date-caption',
  FORM_DATE_INPUT_MONTH: 'date-input-month',
  FORM_DATE_INPUT_DAY: 'date-input-day',
  FORM_DATE_INPUT_YEAR: 'date-input-year',
  FORM_DATE_ERROR: 'date-error',

  PAYMENT_METHOD_SUBMIT: 'payment-method-submit',

  TICKET_NAME: 'ticket-name',
  TICKET_PRICE: 'ticket-price',
  TICKET_QUANTITY: 'ticket-quantity',
  TICKET_TOTAL_PRICE: 'ticket-total-price',

  TICKETS_PICKER: 'tickets-picker',
  TICKETS_PICKER_TITLES: 'tickets-picker-titles',
  TICKETS_PICKER_HEADER_TITLE: 'header-title',
  TICKETS_PICKER_HEADER_PRICE: 'header-price',
  TICKETS_PICKER_HEADER_QUANTITY: 'header-qty',
  TICKETS_PICKER_HEADER_TOTAL: 'header-total',
  TICKETS_PICKER_FOOTER: 'tickets-picker-footer',
  TICKETS_SUMMARY: 'tickets-summary',
  TICKET_WRAPPER: 'ticket-wrapper',

  MEMBERSHIP_OFFERS: 'membership-offers',
  MEMBERSHIP_OFFERS_PROMO: 'membership-offers-promo',
  MEMBERSHIP_OFFERS_DETAILS: 'membership-offers-details',
  MEMBERSHIP_OFFERS_PLAN: 'membership-offers-plan',
  MEMBERSHIP_OFFERS_PLAN_NAME: 'membership-offers-plan-name',
  MEMBERSHIP_OFFERS_PLAN_BENEFIT: 'membership-offers-plan-benefit',
  MEMBERSHIP_OFFERS_PLAN_PRICING: 'membership-offers-plan-pricing',
  MEMBERSHIP_OFFERS_PLAN_INFO: 'membership-offers-plan-info',
  MEMBERSHIP_OFFERS_BUY: 'membership-offers-buy',
  MEMBERSHIP_OFFERS_LOGIN: 'membership-offers-login',
  MEMBERSHIP_SELECTOR: 'membership-selector',

  SHOW_DETAILS_TOGGLE: 'show-details-toggle',
  SHOW_DETAILS_TOGGLE_HIDE: 'show-details-toggle-hide',
  SHOW_DETAILS_TOGGLE_SHOW: 'show-details-toggle-show',

  MEMBERSHIP_SELECTOR_OPTION_TITLE: 'membership-selector-option-title',
  MEMBERSHIP_SELECTOR_OPTION_SUBTITLE: 'membership-selector-option-subtitle',

  FIRST_NAME_INPUT: 'first-name-input',

  THANK_YOU_MESSAGE: 'thank-you-message',
  THANK_YOU_MESSAGE_TITLE: 'thank-you-message-title',
  THANK_YOU_MESSAGE_NOTIFICATION: 'thank-you-message-notification',
  ADD_TO_CALENDAR: 'add-to-calendar',
  SOCIAL_SHARE_TITLE: 'social-share-title',
  SOCIAL_SHARE: 'social-share',
  NAME: 'name',
  DESCRIPTION: 'description',
  MORE_BUTTON: 'more-button',
  TOTAL: 'total',
  PRICE: 'price',
  SOLD_OUT_LABEL: 'sold-out-label',
  DISCOUNT_NAME: 'discount-name',
  AVATAR: 'avatar',
  POLICY: 'policy',
  CHECKBOX: 'checkbox',
  CHECKBOX_INPUT: 'checkbox-input',
  CHECKBOX_LABEL: 'checkbox-label',
  NO_RADIO_BUTTON: 'no-radio-button',
  CLOSED_REGISTRATION: 'closed-registration',
  EVENT_FULL_DATE: 'event-full-date',
  TICKETS_UNAVAILABLE: 'tickets-unavailable',
}

export const hookToAttributeSelector = (dataHook: string) => `[data-hook="${dataHook}"]`
