import * as classNames from 'classnames'
import * as React from 'react'
import * as cl from '../classnames.scss'
import * as s from './subtitle.scss'
import {SubtitleProps} from './index'

export const Subtitle: React.FC<SubtitleProps> = ({dataHook, text, mobile, customColor, compact}) => (
  <h3
    className={classNames(
      cl.evSubtitlesFont,
      customColor || cl.evSubtitlesColor,
      mobile ? s.mobile : s.desktop,
      compact ? s.compact : '',
    )}
    data-hook={dataHook}
  >
    {text}
  </h3>
)
