import * as React from 'react'
import {Ticket} from '../ticket'
import {ColumnsProps} from '.'

export const Columns: React.FC<ColumnsProps> = ({tickets, selectedTickets, selectTicket, unselectTicket, t}) => {
  const onQuantityPickerChange = (ticketId: string, count: number) => {
    const currentCount = selectedTickets[ticketId] ?? 0
    currentCount < count ? selectTicket(ticketId, count) : unselectTicket(ticketId, count)
  }

  return (
    <div role="rowgroup">
      {tickets.map(ticket => (
        <div role="row" key={ticket.id}>
          <Ticket t={t} ticket={ticket} onChange={onQuantityPickerChange} />
        </div>
      ))}
    </div>
  )
}
