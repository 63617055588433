import * as classNames from 'classnames'
import * as React from 'react'
import {DH} from '../../../constants/data-hooks'
import {getAlignmentClass} from '../alignment'
import {RegistrationButton} from '../registration-button'
import * as cl from '../../classnames.scss'
import * as s from './header.scss'
import {Image} from './image'
import {ShortDateLocation} from './short-date-location'
import {HeaderProps} from './index'

export const Header: React.FC<HeaderProps> = ({
  title,
  description,
  descriptionVisible,
  headerAlignment,
  isSquareImage,
  imageVisible,
  onClickRegistrationButton,
}) => (
  <div className={classNames(s.wrapper, imageVisible && (isSquareImage ? s.squareImage : s.wideImage))}>
    <div className={s.headerBackground} />
    <div className={s.container} data-hook={DH.header}>
      <div className={classNames(s.content, getAlignmentClass(headerAlignment))}>
        <ShortDateLocation />
        <Title title={title} />
        <Description description={description} descriptionVisible={descriptionVisible} />
        <RegistrationButton onClick={onClickRegistrationButton} />
      </div>
      <Image />
    </div>
  </div>
)

const Title = ({title}) => (
  <h2 className={classNames(s.eventTitle, cl.evTitleColor, cl.evTitleFont)} data-hook={DH.headerEventTitle}>
    {title}
  </h2>
)

const Description = ({description, descriptionVisible}) =>
  descriptionVisible ? (
    <div className={s.descriptionSpacing}>
      <div
        className={classNames(s.eventDescription, cl.evDescriptionFont, cl.evDescriptionColor)}
        data-hook={DH.headerEventDescription}
      >
        {description}
      </div>
    </div>
  ) : null
